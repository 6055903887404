<template>
  <a-card>
    <a-form :model='form' :onFinish='pageSearchChange' :label-col="{span:2}" :wrapperCol="{span:10}">
      <a-form-item label="为了" name='keywords'>
        <a-input v-model:value="form.keywords" style="width:250px" placeholder="请填写用途"></a-input>，开发者将在获取你的明示同意后，收集你的微信昵称、头像。
      </a-form-item>
      <a-form-item label="为了" name='keywords'>
        <a-input v-model:value="form.keywords" style="width:250px" placeholder="请填写用途"></a-input>，开发者将在获取你的明示同意后，收集你的位置信息。
      </a-form-item>
      <a-form-item label="联系方式" name='keywords' >
        <a-input-group compact>
          <a-select style="width:100px">
            <a-select-option value="Zhejiang">电话</a-select-option>
            <a-select-option value="Jiangsu">邮箱</a-select-option>
            <a-select-option value="Jiangsu">微信</a-select-option>
            <a-select-option value="Jiangsu">qq号</a-select-option>
          </a-select>
          <a-input v-model:value="value4"  style="width:200px"/>
        </a-input-group>
      </a-form-item>
      <a-form-item :wrapper-col="{offset:4}">
        <a-button type="primary"  @click="setTing">保存</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>
<script>
import {privacySetting} from '@/api/appletCode'
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const form = ref({});

    const setTing=async ()=>{

    }
    return {
      form,
      setTing
    };
  },
};
</script>